<template>
  <div class="main">
    <h1>Espere un momento, estamos redirigiendo a la pagina principal</h1>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    token: "",
  }),
  mounted() {
    this.token = this.$route.query.token;
    this.token.replace("#_=_", "");
    this.login();
  },

  methods: {
    login() {
      axios
        .get(
          `https://www.mundouniversitario.mx/api/v1/checkout/login?token=${this.token}`
        )
        .then(() => {
          window.location.href = "/";
        })
        .catch(() => {
        });
    },
  },
};
</script>
